import { createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { errorHandler } from "../../../helpers/errorHandler";
import { Call } from "../../../helpers/fetch";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import { startUpdateStatus } from "../../actions/students/students";

const initialState = {
    data: null,
    notification: null,
    toPay: [],
    selectedCurrency: "",
    promotion: 0,
    isApplyPromotion: false,
    hasConvention: false,
    convention_name: "",
    pay_in: "",
    objectRequest: {},
    openForm: false,
    active: null,
    canDownloadBBVAReference: true,
};

export const billingStatus = createSlice({
    name: "billingStatus",
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        setToPay: (state, action) => {
            state.toPay = action.payload;
        },
        setOpenForm: (state) => {
            state.openForm = true;
        },
        setCloseForm: (state) => {
            state.openForm = false;
        },
        setToPay: (state, action) => {
            state.toPay = action.payload;
        },
        clean: (state) => {
            state.data = null;
        },
        setSelectedCurrency: (state, action) => {
            state.selectedCurrency = action.payload;
        },
        setPromotion: (state, action) => {
            state.promotion = action.payload;
        },
        setApplyPromotion: (state, action) => {
            state.isApplyPromotion = action.payload;
        },
        setConvention: (state, action) => {
            state.hasConvention = action.payload.hasConvention;
            state.convention_name = action.payload.convention_name;
        },
        setPayIn: (state, action) => {
            state.pay_in = action.payload;
        },
        setObjectRequest: (state, action) => {
            state.objectRequest = action.payload;
        },
        setActive: (state, action) => {
            state.active = action.payload;
        },
        setCanDownloadBBVAReference: (state, action) => {
            state.canDownloadBBVAReference = action.payload;
        },
    },
});

export const {
    setData,
    setToPay,
    setSelectedCurrency,
    setPromotion,
    setApplyPromotion,
    clean,
    setConvention,
    setPayIn,
    setObjectRequest,
    setOpenForm,
    setCloseForm,
    setActive,
    setCanDownloadBBVAReference,
} = billingStatus.actions;

export default billingStatus.reducer;

// Actions

export const startCalulateAccountStatus = (
    { cycle_id, currency, promotion = null },
    setErrors
) => {
    return async (dispatch, getState) => {
        dispatch(startUILoading());
        const params = new URLSearchParams();
        params.set("cycle_id", cycle_id);
        params.set("currency", currency);
        params.set("promotion", promotion);

        const resp = await Call(
            `students/account-status/calculate`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            if (resp.success.show_notification) {
                Swal.fire({
                    icon: "success",
                    title: "Pago detectado",
                    text: resp.success.notification,
                });
            }
            dispatch(setApplyPromotion(resp.success.promotion));
            dispatch(setConvention(resp.success));
            dispatch(setPayIn(resp.success.pay_in));
            dispatch(setData(resp.success.data));
            dispatch(
                setCanDownloadBBVAReference(
                    resp.success.canDownloadBBVAReference
                )
            );
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startCalulateAccountStatusByAcademicRecord = (
    {
        academic_record_id,
        cycle_id,
        currency,
        promotion = null,
        updateAcademicRecordStatus = false,
    },
    setErrors,
    cleanData = false
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();
        params.set("cycle_id", cycle_id);
        params.set("currency", currency);
        params.set("academic_record_id", academic_record_id);

        if (promotion) {
            params.set("promotion", promotion);
        }

        const resp = await Call(
            `operators/students/account-status`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            dispatch(setApplyPromotion(resp.success.promotion));
            dispatch(setConvention(resp.success));
            dispatch(setPayIn(resp.success.pay_in));
            dispatch(setData(resp.success.data));
            dispatch(
                setCanDownloadBBVAReference(
                    resp.success.canDownloadBBVAReference
                )
            );

            //* Actualizar el estatus del alumno
            if (updateAcademicRecordStatus) {
                dispatch(startUpdateStatus(resp.success.student.status));
            }
        } else {
            if (cleanData) {
                dispatch(clean());
            } else {
                errorHandler(resp, setErrors);
            }
        }

        dispatch(stopUILoading());
    };
};

export const NotRequiredBillingMonth = (id, objectRequest) => {
    return async (dispatch) => {
        const { obj, setErrors } = objectRequest;

        const result = await Swal.fire({
            title: "¿Estás seguro de realizar esta acción?",
            icon: "warning",
            text: "No podrás revertir esta acción!",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Si, Eliminar!",
            confirmButtonColor: "#2e4a6c",
            cancelButtonColor: "#6c757d",
        });

        if (result.value) {
            dispatch(startUILoading());
            const resp = await Call(
                `students/account-status/not/required/${id}`,
                "POST"
            );

            if (resp.success) {
                Swal.fire({
                    title: "Exito",
                    text: "Se realizó la modificación con éxito",
                    icon: "success",
                });

                dispatch(
                    startCalulateAccountStatusByAcademicRecord(obj, setErrors)
                );
            } else {
                errorHandler(resp, setErrors);
            }
            dispatch(stopUILoading());
        }
    };
};

export const changeBillingStatus = (values, objectRequest, setErrors) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `students/account-status/change-billing-status`,
            "PUT",
            values
        );

        if (resp.success) {
            Swal.fire({
                title: "Exito",
                text: "Se realizó la modificación con éxito",
                icon: "success",
            });

            dispatch(
                startCalulateAccountStatusByAcademicRecord(
                    objectRequest,
                    setErrors
                )
            );
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startBlockByDebt = (values, callback = () => {}, setErrors) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        console.log(values);

        const resp = await Call(
            `students/account-status/block-by-debt`,
            "PUT",
            values
        );

        const { block_by_debt } = values;

        if (resp.success) {
            callback();

            Swal.fire({
                title: "Exito",
                text: `${block_by_debt ? "Bloqueo" : "Desbloqueo"} realizado`,
                icon: "success",
            });
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startDownloadreferences = () => {
    return async (dispatch) => {};
};

export const clearAccountStatus = () => {
    return async (dispatch) => {
        dispatch(clean());
    };
};

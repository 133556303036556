import { useDispatch, useSelector } from "react-redux";
import { useHasPermission } from "../../../../hooks/useHasPermission";
import { IconApp } from "../../../IconApp";
import { NavLinkApp } from "../../../NavLinkApp";
import SidebarNavAccordion from "../../../SidebarNavAccordion";
import { useEffect } from "react";
import { startUIShowModulesCount } from "../../../../redux/actions/administrador/ui/ui";

export const Modules_Cashing = () => {
    const dispatch = useDispatch();
    const { hasPermission } = useHasPermission();
    const { color_text_menu_accordion } = useSelector((state) => state.config);

    const canShowAccordion = hasPermission([
        "DEBTS_BY_MONTH",
        "CURRICULA_TABULATORS_EDIT",
        "CURRICULA_TABULATORS",
        "TABULATORS",
        "TABULATORS_EDIT",
        "PAYMENTS",
        "CONVENTIONS",
        "DISCOUNT_BILLING_MONTH",
        "PAST_DUE_PORTFOLIO",
        "END_OF_MONTH",
    ]);

    useEffect(() => {
        if (canShowAccordion) {
            dispatch(startUIShowModulesCount("Modules_Cashing"));
        }
    }, [canShowAccordion]);

    return (
        <div>
            {canShowAccordion && (
                <SidebarNavAccordion
                    title={"Cobranza"}
                    itemLevel={0}
                    color={color_text_menu_accordion}
                    iconLeftClassName="fa-sharp fa-light fa-sack-dollar fa-xl"
                >
                    {hasPermission("DEBTS_BY_MONTH") && (
                        <NavLinkApp
                            name="Adeudos por mes"
                            to="/app/students/report/debts-by-month"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-circle-dollar-to-slot c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission(["MARKET_CASHIER_ADMIN"]) && (
                        <NavLinkApp
                            name="Compras en caja"
                            to="/app/market-cashier/payments"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-sack-dollar c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission(["MARKET_CASHIER"]) && (
                        <NavLinkApp
                            name="Caja"
                            to="/app/market-cashier"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-cash-register c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}

                    {hasPermission(["PAYMENTS"]) && (
                        <NavLinkApp
                            name="Cobranza"
                            to="/app/payments"
                            icon={
                                <IconApp iconClassName="fa-regular fa-file-invoice-dollar c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission(["PAST_DUE_PORTFOLIO"]) && (
                        <NavLinkApp
                            name="Cartera vencida"
                            to="/app/past-due-portfolio"
                            icon={
                                <IconApp iconClassName="fa-solid fa-business-time c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission(["END_OF_MONTH"]) && (
                        <NavLinkApp
                            name="Cierre de mes"
                            to="/app/end-of-month"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-scale-balanced c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}

                    {hasPermission(["IMPORTED_FILES_BBVA"]) && (
                        <NavLinkApp
                            name="Archivos importados"
                            to="/app/imported-files"
                            icon={
                                <IconApp iconClassName="fa-solid fa-file-import c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}

                    {/* {hasPermision(["CURRICULA_TABULATORS"]) && ( */}

                    {hasPermission([
                        "CHARGE_BY_SUBJECT",
                        "CONVENTIONS",
                        "DISCOUNT_BILLING_MONTH",
                        "PAYMENTS",
                    ]) && (
                        <SidebarNavAccordion
                            title={"Formas de cobro"}
                            itemLevel={1}
                            color={color_text_menu_accordion}
                            iconLeftClassName="fa-solid fa-money-check-dollar-pen fa-xl"
                        >
                            {hasPermission(["CHARGE_BY_SUBJECT"]) && (
                                <NavLinkApp
                                    name="Cobro por materias"
                                    to="/app/charge-by-subjects"
                                    icon={
                                        <IconApp iconClassName="fas fa-regular fa-chalkboard-user c-sidebar-nav-icon"></IconApp>
                                    }
                                />
                            )}
                            {hasPermission(["CONVENTIONS"]) && (
                                <NavLinkApp
                                    name="Convenios"
                                    to="/app/conventions"
                                    icon={
                                        <IconApp iconClassName="fas fa-regular fa-money-bill-transfer c-sidebar-nav-icon"></IconApp>
                                    }
                                />
                            )}

                            {hasPermission(["DISCOUNT_BILLING_MONTH"]) && (
                                <NavLinkApp
                                    name="Descuentos"
                                    to="/app/discount"
                                    icon={
                                        <IconApp iconClassName="fas fa-regular fa-tag c-sidebar-nav-icon"></IconApp>
                                    }
                                />
                            )}

                            {hasPermission(["PAYMENTS"]) && (
                                <NavLinkApp
                                    name="Saldo a favor"
                                    to="/app/payments/positive-balance"
                                    icon={
                                        <IconApp iconClassName="fas fa-regular fa-money-check-dollar-pen c-sidebar-nav-icon"></IconApp>
                                    }
                                />
                            )}
                        </SidebarNavAccordion>
                    )}

                    {hasPermission(["MASSIVE_INVOICE"]) && (
                        <SidebarNavAccordion
                            title={"Facturación"}
                            itemLevel={1}
                            color={color_text_menu_accordion}
                            iconLeftClassName="fa-solid fa-file-invoice-dollar fa-xl"
                        >
                            {hasPermission(["MASSIVE_INVOICE"]) && (
                                <NavLinkApp
                                    name="Facturación"
                                    to="/app/invoice-manual"
                                    icon={
                                        <IconApp iconClassName="fa-regular fa-receipt c-sidebar-nav-icon"></IconApp>
                                    }
                                />
                            )}
                            {hasPermission(["MASSIVE_INVOICE"]) && (
                                <NavLinkApp
                                    name="Facturación masiva"
                                    to="/app/massive-invoice"
                                    icon={
                                        <IconApp iconClassName="fa-solid fa-receipt c-sidebar-nav-icon"></IconApp>
                                    }
                                />
                            )}
                        </SidebarNavAccordion>
                    )}

                    {hasPermission([
                        "CURRICULA_TABULATORS_EDIT",
                        "CURRICULA_TABULATORS",
                        "TABULATORS",
                        "TABULATORS_EDIT",
                    ]) && (
                        <SidebarNavAccordion
                            title={"Catálogos"}
                            itemLevel={1}
                            color={color_text_menu_accordion}
                            iconLeftClassName="fa-sharp fa-regular fa-money-bill-trend-up fa-xl"
                        >
                            {hasPermission([
                                "CURRICULA_TABULATORS_EDIT",
                                "CURRICULA_TABULATORS",
                            ]) && (
                                <NavLinkApp
                                    name="Costos oferta educativa"
                                    to="/app/CurriculaTabulators/list"
                                    icon={
                                        <IconApp iconClassName="fa-solid fa-coins c-sidebar-nav-icon"></IconApp>
                                    }
                                />
                            )}

                            {hasPermission([
                                "TABULATORS",
                                "TABULATORS_EDIT",
                            ]) && (
                                <NavLinkApp
                                    name="Tabuladores docentes"
                                    to="/app/tabulators/list"
                                    icon={
                                        <IconApp iconClassName="fas fa-money-bill c-sidebar-nav-icon"></IconApp>
                                    }
                                />
                            )}
                        </SidebarNavAccordion>
                    )}
                </SidebarNavAccordion>
            )}
        </div>
    );
};

import "animate.css";
import React, { lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { LoadingApp } from "../components/LoadingApp";
import { NotificationContainerApp } from "../components/NotificationContainerApp";
import { PrivateRoute } from "../components/router/PrivateRoute";
import { PublicRoute } from "../components/router/PublicRoute";
import { startFirebaseApp } from "../helpers/FirebaseConfig";
import { useHasPermission } from "../hooks/useHasPermission";
import {
    checkingFinish,
    startRenew as renewUser,
} from "../redux/actions/administrador/auth/auth";
import { startRenew as renewTeacher } from "../redux/actions/administrador/auth/authTeachers";
import { startRenew as renewStudents } from "../redux/actions/administrador/auth/authStudents";
import {
    startUIShowNotification,
    uiSetEnviromentVersions,
} from "../redux/actions/administrador/ui/ui";
import { StudentRoutes } from "./privateRouter/StudentsRoutes";
import { TeachersRoutes } from "./privateRouter/TeachersRoutes";
import { UsersRoutes } from "./privateRouter/UsersRoutes";
import { LogOutRouter } from "./publicRouter/LogOutRouter";

//Screens
const HomeScreen = lazy(() => import("../views/administrador/home/HomeScreen"));

export const AppRouter = () => {
    const dispatch = useDispatch();
    const { checking, session } = useSelector((state) => state.auth);

    const { isLogged } = useHasPermission();

    useEffect(() => {
        dispatch(uiSetEnviromentVersions());

        if (checking) {
            switch (localStorage.getItem("session")) {
                case "operator":
                    dispatch(renewUser());
                    break;
                case "teacher":
                    dispatch(renewTeacher());
                    break;
                case "student":
                    dispatch(renewStudents());
                    break;
                default:
                    dispatch(checkingFinish());
                    break;
            }
        }
    });

    useEffect(() => {
        if (isLogged) {
            //* Se ejecuta despues de los renew y solo si hay usuario autenticado")
            //* SE INICIALIZA FIREBASE  *//
            switch (localStorage.getItem("session")) {
                case "operator":
                    startFirebaseApp((notification) => {
                        dispatch(startUIShowNotification(notification));
                    });
                    break;
                case "teacher":
                    break;
                case "student":
                    break;
                default:
                    break;
            }
        }
    }, [isLogged]);

    if (checking) {
        return (
            <div>
                <LoadingApp />
            </div>
        );
    }

    return (
        <BrowserRouter>
            <div>
                <Routes>
                    <Route
                        path="/app/home"
                        element={
                            <PrivateRoute
                                component={HomeScreen}
                                layout={true}
                            />
                        }
                    />

                    {/* OPERATOR */}
                    {session === "operator" && (
                        <>
                            {/* PRIVATE USERS ROUTES */}
                            <Route
                                path={`/app/profile`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.ProfileScreen}
                                        layout={true}
                                    />
                                }
                            />
                            <Route
                                path="/app/users/map/curricular/:academic_record_id"
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.StudentGradesMapReport
                                        }
                                        permissions={[
                                            "STUDENTS",
                                            "SCHOOL_CONTROL_REPORTS",
                                        ]}
                                        layout={false}
                                    />
                                }
                            />
                            <Route
                                path="/app/users/map/academicHistory/:academic_record_id"
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.StudentGradesMapReport
                                        }
                                        permissions={[
                                            "STUDENTS",
                                            "SCHOOL_CONTROL_REPORTS",
                                        ]}
                                        layout={false}
                                    />
                                }
                            />
                            <Route
                                path="/app/users/list"
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.UserScreen}
                                        layout={true}
                                        permissions={["ADMINISTRADOR"]}
                                    />
                                }
                            />
                            <Route
                                path="/app/settings"
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.SettingScreen}
                                        layout={true}
                                        permissions={["ADMINISTRADOR"]}
                                    />
                                }
                            />
                            <Route
                                path="/app/enterprises"
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.EnterprisesScreen
                                        }
                                        layout={true}
                                        permissions={["ADMINISTRADOR"]}
                                    />
                                }
                            />
                            <Route
                                path="/app/modules/list"
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.ModulesScreen}
                                        layout={true}
                                        permissions={["MODULES"]}
                                    />
                                }
                            />
                            <Route
                                path="/app/roles"
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.RolesScreen}
                                        layout={true}
                                        permissions={["ROLES"]}
                                    />
                                }
                            />
                            <Route
                                path="/app/cycle/students/report"
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.CycleStudentsReportScreen
                                        }
                                        layout={true}
                                        permissions={["CYCLE_STUDENTS_REPORT"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/teacher/attendance/report`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.AttendancesReportScreen
                                        }
                                        layout={true}
                                        permissions={["ATTENDANCES_REPORT"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/class-load/report`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.ClassLoadReportScreen
                                        }
                                        layout={true}
                                        permissions={["CLASS_LOAD_REPORT"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/subject-hours/report`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.SubjectHoursReportScreen
                                        }
                                        layout={true}
                                        permissions={["SUBJECT_HOURS_REPORT"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/class-load/report/:date`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.ClassLoadReportScreen
                                        }
                                        layout={false}
                                        permissions={["CLASS_LOAD_REPORT"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/class-schedules/report`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.ClassSchedulesReportScreen
                                        }
                                        layout={true}
                                        permissions={["CLASS_SCHEDULES_REPORT"]}
                                    />
                                }
                            />

                            <Route
                                path="/app/students/documents/validation"
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.ReviewDocumentsScreen
                                        }
                                        layout={true}
                                        permissions={["AUDIT_DOCUMENTS"]}
                                    />
                                }
                            />

                            <Route
                                path="/app/students/review-status-changes"
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.ReviewStatusesScreen
                                        }
                                        layout={true}
                                        permissions={["REVIEW_STATUS_CHANGES"]}
                                    />
                                }
                            />

                            <Route
                                path="/app/employees/list"
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.EmployeeScreen}
                                        layout={true}
                                        permissions={["EMPLOYEES"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/employees/organization`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.OrganizationScreen
                                        }
                                        layout={true}
                                        permissions={["ORGANIZATION_CHART"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/employees/detail`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.EmployeeDetail}
                                        layout={true}
                                        permissions={["EMPLOYEES"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/employees/schedule`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.SchedulesScreen}
                                        layout={true}
                                        permissions={["EMPLOYEES"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/employees/form`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.SchedulesForm}
                                        layout={true}
                                        permissions={["EMPLOYEES"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/employees/report`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.EmployeeReport}
                                        layout={false}
                                        permissions={["EMPLOYEES"]}
                                    />
                                }
                            />

                            <Route
                                path={`/app/employees/punches/:number_employee/:from/:to`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.PunchesScreen}
                                        layout={true}
                                        permissions={[
                                            "EMPLOYEES",
                                            "AREA_DIRECTOR",
                                            "INCIDENTS",
                                        ]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/employees/all/punches/:number_employee/:from/:to`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.AllPunchesScreen}
                                        layout={true}
                                        permissions={[
                                            "EMPLOYEES",
                                            "AREA_DIRECTOR",
                                            "INCIDENTS",
                                        ]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/department/list`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.DepartmentScreen}
                                        layout={true}
                                        permissions={["EMPLOYEES"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/incidents/list`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.IncidentsScreen}
                                        layout={true}
                                        permissions={["INCIDENTS"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/incident/log/:incident_id`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.IncidentLog}
                                        layout={false}
                                        permissions={[
                                            "INCIDENTS",
                                            "AREA_DIRECTOR",
                                        ]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/area_director/list`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.AreaDirectorsScreen
                                        }
                                        layout={true}
                                        permissions={["AREA_DIRECTOR"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/area_director/list`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.AreaDirectorsScreen
                                        }
                                        layout={true}
                                        permissions={["AREA_DIRECTOR"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/vacations/list`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.VacationScreen}
                                        layout={true}
                                        permissions={["VACATIONS_CORPORATIVE"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/calendar`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.CalendarScreen}
                                        layout={true}
                                        permissions={["INCIDENTS_CAPTURE"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/incidents/reports`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.ReportPayrollScreen
                                        }
                                        layout={true}
                                        permissions={["REPORT_PAYROLL"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/manuals/administrator`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.ManualsScreen}
                                        layout={true}
                                        permissions={["MANUALS"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/manuals/public`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.PublicManualScreen
                                        }
                                        layout={true}
                                        permissions={["SHOW_MANUALS"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/formats/administrator`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.FormatScreen}
                                        layout={true}
                                        permissions={["FORMATS"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/formats/public`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.PublicFormatScreen
                                        }
                                        layout={true}
                                        permissions={["FORMATS_PUBLIC"]}
                                    />
                                }
                            />
                            {/* <Route path={"/app/soporte"}> */}
                            <Route
                                path={"/app/soporte"}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.SupportTIByUser}
                                        layout={true}
                                        permissions={["TICKETS_USERS"]}
                                    />
                                }
                            />
                            <Route
                                path={"/app/soporte/:ticket"}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.SupportTIByUser}
                                        layout={true}
                                        permissions={["TICKETS_USERS"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/soporte/tickets/:ticket`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.SupportTIScreen}
                                        layout={true}
                                        permissions={["SUPPORT_TI"]}
                                    />
                                }
                            />
                            <Route
                                path={"/app/soporte/tickets"}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.SupportTIScreen}
                                        layout={true}
                                        permissions={["SUPPORT_TI"]}
                                    />
                                }
                            />
                            {/* </Route> */}
                            <Route
                                path={`/app/eventos`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.EventScreen}
                                        layout={true}
                                        permissions={["EVENTS", "EVENTS_ADMIN"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/teachers/list`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.TeacherScreen}
                                        layout={true}
                                        permissions={[
                                            "TEACHERS",
                                            "TEACHERS_READONLY",
                                        ]}
                                    />
                                }
                            />
                            <Route path="/app/teachers/form/">
                                <Route
                                    path={`:teacher_id`}
                                    element={
                                        <PrivateRoute
                                            component={
                                                UsersRoutes.TeacherDetail
                                            }
                                            layout={true}
                                            permissions={["TEACHERS"]}
                                        />
                                    }
                                />
                                <Route
                                    path=""
                                    element={
                                        <PrivateRoute
                                            component={
                                                UsersRoutes.TeacherDetail
                                            }
                                            layout={true}
                                            permissions={["TEACHERS"]}
                                        />
                                    }
                                />
                            </Route>
                            <Route
                                path={`/app/teachers/v2/payroll/list`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.CutsPayroll}
                                        layout={true}
                                        permissions={["PAYROLL_TEACHERS_V2"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/teachers/payrolls/list/:payroll_id`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.TeacherPayroll}
                                        layout={true}
                                        permissions={"TEACHERS"}
                                    />
                                }
                            />
                            <Route
                                path={`/app/teachers/payrolls/list`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.PayrollScreen}
                                        layout={true}
                                        permissions={["TEACHERS"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/teachers/v2/justify/list`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.JustifyScreen}
                                        layout={true}
                                        permissions={["PAYROLL_TEACHERS_V2"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/teachers/payrolls/list/:payroll_id/teacher/:payroll_teacher_id`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.TeacherPayrollDetail
                                        }
                                        layout={true}
                                        permissions={["TEACHERS"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/teacher/cycles/list`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.CycleScreen}
                                        layout={true}
                                        permissions={["CYCLES"]}
                                    />
                                }
                            />
                            {/* Curricula Screen */}
                            <Route
                                path={`/app/curricula/list`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.CurriculaScreen}
                                        layout={true}
                                        permissions={[
                                            "PAYROLL_TEACHERS_V2",
                                            "PLANS_AND_STUDY_PROGRAMS",
                                        ]}
                                    />
                                }
                            />
                            {/* Curricula Edit */}
                            <Route
                                path={`/app/curricula/edit/:curricula_id`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.CurriculaEdit}
                                        layout={true}
                                        permissions={[
                                            "PAYROLL_TEACHERS_V2",
                                            "PLANS_AND_STUDY_PROGRAMS",
                                        ]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/school_subjects/list`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.SchoolSubjectScreen
                                        }
                                        layout={true}
                                        permissions={[
                                            "PAYROLL_TEACHERS_V2",
                                            "PLANS_AND_STUDY_PROGRAMS",
                                        ]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/teachers/punches/:cuts_payroll_id/:teacher_id`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.TeacherPunch}
                                        layout={true}
                                        permissions={[
                                            "TEACHERS",
                                            "TEACHERS_READONLY",
                                            "PAYROLL_TEACHERS_REPORT",
                                        ]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/teachers/punches/:cuts_payroll_id`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.TeacherPunch}
                                        layout={true}
                                        permissions={[
                                            "TEACHERS",
                                            "TEACHERS_READONLY",
                                            "PAYROLL_TEACHERS_REPORT",
                                        ]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/tabulators/list`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.TabulatorScreen}
                                        layout={true}
                                        permissions={[
                                            "TABULATORS",
                                            "TABULATORS_EDIT",
                                        ]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/modules/teachers/groups/list`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.GroupScreen}
                                        layout={true}
                                        permissions={["MODULES_TEACHERS"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/modules/teachers/list`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.ModulesScreenTeacher
                                        }
                                        layout={true}
                                        permissions={["MODULES_TEACHERS"]}
                                    />
                                }
                            />
                            {/* <Route
                                path={`/app/ead/lista`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.EADScreen}
                                        layout={true}
                                        permissions={["EAD_LIST"]}
                                    />
                                }
                            /> */}
                            <Route
                                path={`/app/ead/lista`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.EADScreen}
                                        layout={true}
                                        permissions={["EAD_LIST","EAD_READ_ONLY"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/students/electronic-titles`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.ElectronicTitlesScreen
                                        }
                                        layout={true}
                                        permissions={["ELECTRONIC_TITLES"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/professional_practices/list`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.ProfessionalPracticesScreen
                                        }
                                        layout={true}
                                        permissions={["PROFESSIONAL_PRACTICES"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/social_services/list`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.SocialServiceDetail
                                        }
                                        layout={true}
                                        permissions={["SERVICE_SOCIAL"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/professional_practices/detail`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.ProfessionalPracticesDetail
                                        }
                                        layout={true}
                                        permissions={["PROFESSIONAL_PRACTICES"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/service_social/list`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.SocialServiceScreen
                                        }
                                        layout={true}
                                        permissions={["SERVICE_SOCIAL"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/partnerships`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.PartnershipsScreen
                                        }
                                        layout={true}
                                        permissions={["PARTNERSHIPS"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/students`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.StudentsScreen}
                                        layout={true}
                                        permissions={[
                                            "STUDENTS",
                                            "READ_STUDENTS",
                                        ]}
                                    />
                                }
                            />

                            <Route
                                path={`/app/students/admission`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.StudentsScreenAdmission
                                        }
                                        layout={true}
                                        permissions={"ADMISSION_STUDENTS"}
                                    />
                                }
                            />

                            <Route
                                path={`/app/students/report/payments/:student_number`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.StudentsReport}
                                        layout={false}
                                        permissions={["STUDENTS"]}
                                    />
                                }
                            />

                            <Route
                                path={`/app/students/form/:student_number`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.StudentDetail}
                                        layout={true}
                                        permissions={[
                                            "STUDENTS",
                                            "READ_STUDENT_DETAIL",
                                            "READ_LEAD_DETAIL",
                                            "ADMISSION_STUDENTS",
                                        ]}
                                    />
                                }
                            />

                            <Route
                                path={`/app/students/algebraix/generate/:student_number`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.GenerateXMLScreen
                                        }
                                        layout={true}
                                        permissions={["STUDENTS"]}
                                    />
                                }
                            />

                            <Route
                                path={`/app/students/aclarations/list`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.OperatorClarificationScreen
                                        }
                                        layout={true}
                                        permissions={["STUDENTS"]}
                                    />
                                }
                            />

                            <Route
                                path={`/app/shopping/providers`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.ProviderScreen}
                                        layout={true}
                                        permissions={["PROVIDERS"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/shopping/purchases`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.PurchaseScreen}
                                        layout={true}
                                        permissions={[
                                            "PURCHASES_MANAGEMENT",
                                            "PURCHASES",
                                        ]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/shopping/budgets`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.BudgetScreen}
                                        layout={true}
                                        permissions={["BUDGETS"]}
                                    />
                                }
                            />
                            {/* CORDINATIONS */}
                            <Route
                                path={`/app/operators/coordinations`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.CoordinationScreen
                                        }
                                        layout={true}
                                        permissions={["COORDINATIONS"]}
                                    />
                                }
                            />
                            {/* academicgroups */}
                            {/* <Route path="/app/operators/academicgroups"> */}
                            <Route
                                path={"/app/operators/academicgroups"}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.AcademicGroupsScreen
                                        }
                                        layout={true}
                                        permissions={["SCHOOL_GROUPS"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/operators/academicgroups/:group_id`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.AcademicGroupsEdit
                                        }
                                        layout={true}
                                        permissions={["SCHOOL_GROUPS"]}
                                    />
                                }
                            />

                            <Route
                                path={"/app/operators/extracurricular-subjects"}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.ExtracurricularSubjectsScreen
                                        }
                                        layout={true}
                                        permissions={[
                                            "EXTRACURRICULAR_SUBJECTS",
                                        ]}
                                    />
                                }
                            />
                            {/* </Route> */}
                            {/* infrastructure */}
                            {/* <Route path="/app/operators/infrastructure/building"> */}
                            <Route
                                path={"/app/operators/infrastructure/building"}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.BuildingScreen}
                                        layout={true}
                                        permissions={["INFRASTRUCTURE"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/operators/infrastructure/building/:building_code`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.BuildingEdit}
                                        layout={true}
                                        permissions={["INFRASTRUCTURE"]}
                                    />
                                }
                            />
                            {/* </Route> */}
                            <Route path="/app/operators/infrastructure/classroom/">
                                <Route
                                    path={`:building_code`}
                                    element={
                                        <PrivateRoute
                                            component={
                                                UsersRoutes.ClassroomScreen
                                            }
                                            layout={true}
                                            permissions={["INFRASTRUCTURE"]}
                                        />
                                    }
                                />
                                <Route
                                    path={`edit/:classroom_name/:building_id`}
                                    element={
                                        <PrivateRoute
                                            component={
                                                UsersRoutes.ClassroomEdit
                                            }
                                            layout={true}
                                            permissions={["INFRASTRUCTURE"]}
                                        />
                                    }
                                />
                            </Route>

                            <Route
                                path={"/app/school_of_origins"}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.SchoolOfOriginScreen
                                        }
                                        layout={true}
                                        permissions={["SCHOOLS_OF_ORIGINS"]}
                                    />
                                }
                            />

                            <Route
                                path={"/app/CurriculaTabulators/list"}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.CurriculaTabulatorScreen
                                        }
                                        layout={true}
                                        permissions={[
                                            "CURRICULA_TABULATORS_EDIT",
                                            "CURRICULA_TABULATORS",
                                        ]}
                                    />
                                }
                            />
                            <Route
                                path={"/app/scholarships"}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.ScholarshipsScreen
                                        }
                                        layout={true}
                                        permissions={["SCHOLARSHIPS"]}
                                    />
                                }
                            />
                            <Route
                                path={"/app/inscriptionsummary"}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.InscriptionSummaryScreen
                                        }
                                        layout={true}
                                        permissions={["INSCRIPTION_SUMMARY"]}
                                    />
                                }
                            />
                            <Route
                                path={"/app/conventions"}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.ConventionScreen}
                                        layout={true}
                                        permissions={["CONVENTIONS"]}
                                    />
                                }
                            />
                            <Route
                                path={"/app/payments"}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.PaymentsScreen}
                                        layout={true}
                                        permissions={["PAYMENTS"]}
                                    />
                                }
                            />

                            <Route
                                path={"/app/past-due-portfolio"}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.PastduePortfolioScreen
                                        }
                                        layout={true}
                                        permissions={["PAST_DUE_PORTFOLIO"]}
                                    />
                                }
                            />

                            <Route
                                path={`/app/payments/positive-balance`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.PositiveBalanceScreen
                                        }
                                        layout={true}
                                        permissions={["PAYMENTS"]}
                                    />
                                }
                            />

                            <Route
                                path={`/app/discount`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.DiscountScreen}
                                        layout={true}
                                        permissions={["DISCOUNT_BILLING_MONTH"]}
                                    />
                                }
                            />

                            {/* Reportes de control escolar */}
                            <Route
                                path={`/app/school/control/reports`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.SchoolControlReportsScreen
                                        }
                                        layout={true}
                                        permissions={["SCHOOL_CONTROL_REPORTS"]}
                                    />
                                }
                            />

                            {/* Reportes de carga de calificaciones */}
                            <Route
                                path={`/app/school/grade/report`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.GradeUploadReportScreen
                                        }
                                        layout={true}
                                        permissions={"GRADE_UPLOAD_REPORT"}
                                    />
                                }
                            />

                            {/* Reportes de docentes por ciclo */}
                            <Route
                                path={`/app/teachers/by/cycle`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.TeachersByCycleReportScreen
                                        }
                                        layout={true}
                                        permissions={"TEACHERS_BY_CYCLE_REPORT"}
                                    />
                                }
                            />

                            {/* Reporte de adeudos por mes */}
                            <Route
                                path={`/app/students/report/debts-by-month`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.DebtsByMonthReportsScreen
                                        }
                                        layout={true}
                                        permissions={"DEBTS_BY_MONTH"}
                                    />
                                }
                            />

                            {/* Reporte de becas */}
                            <Route
                                path={`/app/school/scholarships/report`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.ScholarshipsReportsScreen
                                        }
                                        layout={true}
                                        permissions={"SCHOLARSHIPS_REPORT"}
                                    />
                                }
                            />

                            {/* Reporte de calificaciones por materia */}
                            <Route
                                path={`/app/school/grade/report/by/subject`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.GradesBySubjectScreen
                                        }
                                        layout={true}
                                        permissions={"GRADES_BY_SUBJECT_REPORT"}
                                    />
                                }
                            />

                            {/* Descargas de kardex */}
                            <Route
                                path={`/app/download/kardex`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.DownloadKardexScreen}
                                        layout={true}
                                        permissions={["DOWNLOAD_KARDEX"]}
                                    />
                                }
                            />

                            {/* Editar calificaciones */}
                            <Route
                                path={`/app/school/grades/edit`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.GradesEditScreen}
                                        layout={true}
                                        permissions={["GRADES_EDIT"]}
                                    />
                                }
                            />

                            {/* Reportes de estatus académico de estudiantes */}
                            <Route
                                path={`/app/students/academic/status`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.AcademicStatusReportsScreen
                                        }
                                        layout={true}
                                        permissions={"ACADEMIC_STATUS_REPORT"}
                                    />
                                }
                            />

                            {/* Reportes de inscripciones por ciclo y carrera */}
                            <Route
                                path={`/app/students/report/inscriptions-cycle`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.InscriptionsByCycleReportsScreen
                                        }
                                        layout={true}
                                        permissions={
                                            "INSCRIPTIONS_BY_CYCLE_REPORT"
                                        }
                                    />
                                }
                            />

                            {/* Reportes de placas vehiculares */}
                            <Route
                                path={`/app/vehicle-plates/report`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.VehiclePlatesReportsScreen
                                        }
                                        layout={true}
                                        permissions={"VEHICLE_PLATES_REPORT"}
                                    />
                                }
                            />

                            {/* Reportes de promedios generales */}
                            <Route
                                path={`/app/school/grade/report/averages/general`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.AveragesReportsGeneralScreen
                                        }
                                        layout={true}
                                        permissions={"GENERAL_AVERAGES_REPORT"}
                                    />
                                }
                            />

                            {/* Reportes de escuelas de procedencia de estudiantes */}
                            <Route
                                path={`/app/students/report/origin-schools`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.OriginSchoolsReportScreen
                                        }
                                        layout={true}
                                        permissions={"ACADEMIC_STATUS_REPORT"}
                                    />
                                }
                            />

                            {/* Listas de distribución */}
                            <Route
                                path={`/app/distribution_lists`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.DistributionListsScreen
                                        }
                                        layout={true}
                                        permissions={["DISTRIBUTION_LISTS"]}
                                    />
                                }
                            />

                            {/* Avisos operador */}
                            <Route
                                path={`/app/news`}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.NewsScreen}
                                        layout={true}
                                        permissions={["CAN_REGISTER_NEWS"]}
                                    />
                                }
                            />

                            {/* Aclaraciones operador */}
                            <Route
                                path={`/app/school/clarifications`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.AclarationsScreen
                                        }
                                        layout={true}
                                        permissions={["CLARIFICATIONS"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/market-cashier/payments`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.MarketCashierPayments
                                        }
                                        layout={true}
                                        permissions={["MARKET_CASHIER"]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/market-cashier`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.MarketCashierScreen
                                        }
                                        layout={true}
                                        permissions={["MARKET_CASHIER"]}
                                    />
                                }
                            />

                            <Route
                                path="/app/payments/receipt/:payment_id"
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.GenerateReceiptScreen
                                        }
                                        layout={false}
                                        permissions={[
                                            "PAYMENTS",
                                            "MARKET_CASHIER",
                                        ]}
                                    />
                                }
                            />

                            <Route
                                path={`/app/update/massive/students`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.MassiveUpdateStudentsScreen
                                        }
                                        layout={true}
                                        permissions={[
                                            "UPDATE_MASSIVE_STUDENTS",
                                        ]}
                                    />
                                }
                            />

                            <Route
                                path={`/app/massive-invoice`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.MasiveInvoiceScreen
                                        }
                                        layout={true}
                                        permissions={["MASSIVE_INVOICE"]}
                                    />
                                }
                            />

                            <Route
                                path={`/app/invoice-manual`}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.InvoiceManualScreen
                                        }
                                        layout={true}
                                        permissions={["MASSIVE_INVOICE"]}
                                    />
                                }
                            />

                            <Route
                                path={"/app/imported-files"}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.ImportedFiles}
                                        layout={true}
                                        permissions={["IMPORTED_FILES_BBVA"]}
                                    />
                                }
                            />
                            <Route
                                path={"/app/charge-by-subjects"}
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.ChargeBySubjectScreen
                                        }
                                        layout={true}
                                        permissions={["CHARGE_BY_SUBJECT"]}
                                    />
                                }
                            />
                            <Route
                                path={"/app/end-of-month"}
                                element={
                                    <PrivateRoute
                                        component={UsersRoutes.EndOfMonthScreen}
                                        layout={true}
                                        permissions={["END_OF_MONTH"]}
                                    />
                                }
                            />
                        </>
                    )}

                    {/* ESTUDENTS */}
                    {session === "student" && (
                        <>
                            <Route
                                path={`/app/profile`}
                                element={
                                    <PrivateRoute
                                        component={
                                            StudentRoutes.ProfileStudentScreen
                                        }
                                        layout={true}
                                    />
                                }
                            />

                            <Route
                                path={`/app/students/payu/form`}
                                element={
                                    <PrivateRoute
                                        component={StudentRoutes.FormPayUTest}
                                        permissions={["SESSION_STUDENT"]}
                                        layout={true}
                                    />
                                }
                            />

                            {/* Billing student */}
                            <Route
                                path="/students/billing/status"
                                element={
                                    <PrivateRoute
                                        component={
                                            StudentRoutes.StudentsBillingStatus
                                        }
                                        permissions={["SESSION_STUDENT"]}
                                        layout={true}
                                    />
                                }
                            />
                            <Route
                                path="/students/billing/shopping-cart"
                                element={
                                    <PrivateRoute
                                        component={
                                            StudentRoutes.StudentsBillingShoppingCart
                                        }
                                        permissions={["SESSION_STUDENT"]}
                                        layout={true}
                                    />
                                }
                            />
                            <Route
                                path="/students/billing/payments-history"
                                element={
                                    <PrivateRoute
                                        component={
                                            StudentRoutes.StudentPaymentsHistoryScreen
                                        }
                                        permissions={["SESSION_STUDENT"]}
                                        layout={true}
                                    />
                                }
                            />
                            <Route
                                path="/students/load-documents"
                                element={
                                    <PrivateRoute
                                        component={
                                            StudentRoutes.StudentLoadDocumentsScreen
                                        }
                                        permissions={["SESSION_STUDENT"]}
                                        layout={true}
                                    />
                                }
                            />

                            {/* Schedule student */}
                            <Route
                                path="/students/schedule/screen"
                                element={
                                    <PrivateRoute
                                        component={
                                            StudentRoutes.StudentsScheduleScreen
                                        }
                                        permissions={["SESSION_STUDENT"]}
                                        layout={true}
                                    />
                                }
                            />

                            {/* Attendance student */}
                            <Route
                                path="/students/attendances"
                                element={
                                    <PrivateRoute
                                        component={
                                            StudentRoutes.StudentsAttendanceScreen
                                        }
                                        permissions={["SESSION_STUDENT"]}
                                        layout={true}
                                    />
                                }
                            />

                            {/* Scholarship request student */}
                            <Route
                                path="/students/scholarship-request/screen"
                                element={
                                    <PrivateRoute
                                        component={
                                            StudentRoutes.StudentsScholarshipScreen
                                        }
                                        permissions={["SESSION_STUDENT"]} // , SCHOLARSHIP_REQUEST
                                        layout={true}
                                    />
                                }
                            />

                            {/* Scholarship extra payments */}
                            <Route
                                path="/students/extra-payments/screen"
                                element={
                                    <PrivateRoute
                                        component={
                                            StudentRoutes.StudentExtraPaymentsScreen
                                        }
                                        permissions={["SESSION_STUDENT"]} // extra payments
                                        layout={true}
                                    />
                                }
                            />

                            {/* Calificaciones estudiante */}
                            <Route
                                path="/students/grades"
                                element={
                                    <PrivateRoute
                                        component={
                                            StudentRoutes.StudentGradesScreen
                                        }
                                        permissions={["SESSION_STUDENT"]}
                                        layout={true}
                                    />
                                }
                            />

                            {/* Materias del estudiante */}
                            <Route
                                path="/students/subjects"
                                element={
                                    <PrivateRoute
                                        component={
                                            StudentRoutes.StudentSubjectsScreen
                                        }
                                        permissions={["SESSION_STUDENT"]}
                                        layout={true}
                                    />
                                }
                            />
                            {/* Solicitud de aclaraciones */}
                            <Route
                                path="/students/aclarations"
                                element={
                                    <PrivateRoute
                                        component={
                                            StudentRoutes.StudentClarificationScreen
                                        }
                                        permissions={["SESSION_STUDENT"]}
                                        layout={true}
                                    />
                                }
                            />

                            <Route
                                path="/students/internship"
                                element={
                                    <PrivateRoute
                                        component={
                                            StudentRoutes.InternshipSocialServiceScreen
                                        }
                                        permissions={["SESSION_STUDENT"]}
                                        layout={true}
                                    />
                                }
                            />
                            <Route
                                path="/app/payments/receipt/:payment_id"
                                element={
                                    <PrivateRoute
                                        component={
                                            UsersRoutes.GenerateReceiptScreen
                                        }
                                        layout={false}
                                        permissions={["SESSION_STUDENT"]}
                                    />
                                }
                            />
                        </>
                    )}

                    {/* COMPARTIDAS */}
                    {["operator", "student"].includes(session) && (
                        <>
                            <Route
                                path="/students/document-orphanhood/:academic_record_id?"
                                element={
                                    <PrivateRoute
                                        component={
                                            StudentRoutes.StudentOrphanhoodPDF
                                        }
                                        layout={false}
                                    />
                                }
                            />

                            <Route
                                path="/students/inscription-document/:academic_record_id?"
                                element={
                                    <PrivateRoute
                                        component={
                                            StudentRoutes.StudentInscriptionDocument
                                        }
                                        layout={false}
                                        // permissions={["SESSION_STUDENT","ADMISSION_STUDENTS"]}
                                    />
                                }
                            />
                            <Route
                                path="/students/generate-references"
                                element={
                                    <PrivateRoute
                                        component={
                                            StudentRoutes.GenerateReferencesScreen
                                        }
                                        layout={false}
                                    />
                                }
                            />
                        </>
                    )}

                    {/* TEACHER */}
                    {session === "teacher" && (
                        <>
                            <Route
                                path={`/app/profile`}
                                element={
                                    <PrivateRoute
                                        component={
                                            TeachersRoutes.ProfileTeacherScreen
                                        }
                                        layout={true}
                                    />
                                }
                            />
                            <Route
                                path={`/app/teacher/oclock`}
                                element={
                                    <PrivateRoute
                                        component={TeachersRoutes.TeacherOclock}
                                        layout={true}
                                        permissions={["TEACHER_CHECK_IN"]}
                                    />
                                }
                            />

                            <Route
                                path={`/app/teacher/schedule`}
                                element={
                                    <PrivateRoute
                                        component={
                                            TeachersRoutes.TeachersSchedule
                                        }
                                        layout={true}
                                        permissions={["TEACHERS_CALENDAR"]}
                                    />
                                }
                            />

                            <Route
                                path={`/app/teacher/cuts/punches`}
                                element={
                                    <PrivateRoute
                                        component={
                                            TeachersRoutes.TeacherPunches
                                        }
                                        layout={true}
                                        permissions={[
                                            "TEACHERS_PAYROLL_BY_TEACHERS",
                                        ]}
                                    />
                                }
                            />

                            {/* Grupos docente*/}

                            <Route
                                path={`/app/teacher/groups`}
                                element={
                                    <PrivateRoute
                                        component={
                                            TeachersRoutes.TeacherGroupsScreen
                                        }
                                        layout={true}
                                        permissions={["TEACHER_GROUPS"]}
                                    />
                                }
                            />

                            {/* Grupos docente por id*/}

                            <Route
                                path={`/app/teacher/groups/:group_id`}
                                element={
                                    <PrivateRoute
                                        component={
                                            TeachersRoutes.TeacherGroupById
                                        }
                                        layout={true}
                                        permissions={["TEACHER_GROUPS"]}
                                    />
                                }
                            />

                            {/* Materias docente por id*/}
                            <Route
                                path={`/app/teacher/subjects/:subject_id`}
                                element={
                                    <PrivateRoute
                                        component={
                                            TeachersRoutes.TeacherSubjectById
                                        }
                                        layout={true}
                                        permissions={["TEACHER_SUBJECTS"]}
                                    />
                                }
                            />

                            {/* Materias docente*/}

                            <Route
                                path={`/app/teacher/subjects`}
                                element={
                                    <PrivateRoute
                                        component={
                                            TeachersRoutes.TeacherSubjectsScreen
                                        }
                                        layout={true}
                                        permissions={["TEACHER_SUBJECTS"]}
                                    />
                                }
                            />

                            {/* Calificaciones (ordinarios) */}
                            <Route
                                path={`/app/teacher/grades/ordinaries`}
                                element={
                                    <PrivateRoute
                                        component={
                                            TeachersRoutes.TeacherOrdinariesScreen
                                        }
                                        layout={true}
                                        permissions={["TEACHER_GRADES"]}
                                    />
                                }
                            />

                            {/* Extraordinarios */}
                            <Route
                                path={`/app/teacher/grades/extraordinaries`}
                                element={
                                    <PrivateRoute
                                        component={
                                            TeachersRoutes.TeacherExtraordinariesScreen
                                        }
                                        layout={true}
                                        permissions={[
                                            "TEACHER_EXTRAORDINARIES",
                                        ]}
                                    />
                                }
                            />

                            {/* Materias docente*/}
                            <Route
                                path={`/app/teacher/attendance`}
                                element={
                                    <PrivateRoute
                                        component={
                                            TeachersRoutes.TeacherAttendancesScreen
                                        }
                                        layout={true}
                                        permissions={[
                                            "TEACHER_ATTENDENCE_BY_STUDENT",
                                        ]}
                                    />
                                }
                            />
                            <Route
                                path={`/app/teacher/attendance/by-currriculum/:academic_schedule_id/:date/:hour`}
                                element={
                                    <PrivateRoute
                                        component={
                                            TeachersRoutes.TeacherAttendanceBySubject
                                        }
                                        layout={true}
                                        permissions={[
                                            "TEACHER_ATTENDENCE_BY_STUDENT",
                                        ]}
                                    />
                                }
                            />

                            {/* Avances programáticos */}
                            <Route
                                path={`/app/teacher/programmatic-advance`}
                                element={
                                    <PrivateRoute
                                        component={
                                            TeachersRoutes.TeacherProgrammaticAdvanceScreen
                                        }
                                        layout={true}
                                        permissions={["PROGRAMMATIC_ADVANCE"]}
                                    />
                                }
                            />
                        </>
                    )}

                    {/*  PUBLIC ROUTES */}
                    <Route
                        exact
                        path="/"
                        element={
                            <PublicRoute
                                component={LogOutRouter.LoginScreen}
                                layout={true}
                            />
                        }
                    />
                    {/* Auth */}
                    <Route
                        exact
                        path="/login"
                        element={
                            <PublicRoute
                                component={LogOutRouter.LoginScreen}
                                layout={true}
                            />
                        }
                    />
                    <Route
                        path="/password/reset"
                        element={
                            <PublicRoute
                                component={LogOutRouter.PasswordReset}
                                layout={true}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/recover/password"
                        element={
                            <PublicRoute
                                component={LogOutRouter.ForgotMyPassword}
                                layout={true}
                            />
                        }
                    />

                    {/* Auth Docentes */}
                    <Route
                        exact
                        path="/teachers/login"
                        element={
                            <PublicRoute
                                component={LogOutRouter.LoginTeachersScreen}
                                layout={true}
                            />
                        }
                    />
                    <Route
                        path="/teachers/password/reset"
                        element={
                            <PublicRoute
                                component={LogOutRouter.PasswordResetTeacher}
                                layout={true}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/teachers/recover/password"
                        element={
                            <PublicRoute
                                component={LogOutRouter.ForgotMyPasswordTeacher}
                                layout={true}
                            />
                        }
                    />

                    {/* Auth Estudiantes */}
                    <Route
                        exact
                        path="/students/login"
                        element={
                            <PublicRoute
                                component={LogOutRouter.LoginStudentsScreen}
                                layout={true}
                            />
                        }
                    />

                    <Route
                        path="/students/password/reset"
                        element={
                            <PublicRoute
                                component={LogOutRouter.PasswordResetStudents}
                                layout={true}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/students/recover/password"
                        element={
                            <PublicRoute
                                component={
                                    LogOutRouter.ForgotMyPasswordStudents
                                }
                                layout={true}
                            />
                        }
                    />

                    <Route
                        exact
                        path="/solicitud-ingreso"
                        element={
                            <PublicRoute
                                component={LogOutRouter.AdmissionApplication}
                                layout={true}
                            />
                        }
                    />

                    <Route
                        exact
                        path="/solicitud-ingreso-exitosa"
                        element={
                            <PublicRoute
                                component={
                                    LogOutRouter.AdmissionApplicationSucces
                                }
                                layout={true}
                            />
                        }
                    />

                    <Route path="/*" element={<Navigate to={"/app/home"} />} />
                </Routes>
                <LoadingApp />
                <NotificationContainerApp />
            </div>
        </BrowserRouter>
    );
};

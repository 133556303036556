export const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];

export const weekDaysES = [
    { value: "Monday", label: "Lunes", short: "monday" },
    { value: "Tuesday", label: "Martes", short: "tuesday" },
    { value: "Wednesday", label: "Miércoles", short: "wednesday" },
    { value: "Thursday", label: "Jueves", short: "thursday" },
    { value: "Friday", label: "Viernes", short: "friday" },
    { value: "Saturday", label: "Sábado", short: "saturday" },
    // { value: "Sunday", label: "Domingo", short: "sunday" },
];


export const weekDaysEN = {
    lunes: "Monday",
    martes: "Tuesday",
    miércoles: "Wednesday",
    jueves: "Thursday",
    viernes: "Friday",
    sábado: "Saturday",
    domingo: "Sunday",
};

export const weekDaysENArray = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
];

import PropTypes from "prop-types";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { downloadFileFromLink } from "../helpers/fetch";

export const IconApp = ({
    iconClassName,
    color,
    onClick = () => {},
    description = "_",
    isClickable = false,
    href = "",
    target = "_self",
    fontSize = "",
    disabled = false,
    downloadFileName = "",
}) => {
    let styleIcon = {};

    if (color) {
        styleIcon.color = color;
    }
    if (isClickable) {
        styleIcon.cursor = "pointer";
    }
    if (fontSize) {
        styleIcon.fontSize = fontSize;
    }

    const getIcon = () => {
        if (href && downloadFileName) {
            return (
                <i
                    className={iconClassName}
                    aria-hidden="true"
                    style={styleIcon}
                    onClick={() => downloadFileFromLink(href, downloadFileName)}
                ></i>
            );
        } else if (href) {
            return (
                <Link
                    to={href}
                    style={{ color: "inherit" }}
                    target={target}
                    rel="noopener noreferrer"
                >
                    <i
                        className={iconClassName}
                        aria-hidden="true"
                        style={styleIcon}
                        onClick={onClick}
                    ></i>
                </Link>
            );
        } else {
            return (
                <i
                    className={iconClassName}
                    aria-hidden="true"
                    style={styleIcon}
                    onClick={onClick}
                ></i>
            );
        }
    };

    return (
        <>
            {disabled ? (
                <OverlayTrigger
                    placement={description ? "bottom" : "click"}
                    overlay={
                        <Tooltip hidden={description === "_"}>
                            {description}
                        </Tooltip>
                    }
                >
                    <i
                        className={iconClassName + " disabled"}
                        aria-hidden="true"
                        style={styleIcon}
                    ></i>
                </OverlayTrigger>
            ) : (
                <OverlayTrigger
                    placement={description ? "bottom" : "click"}
                    overlay={
                        <Tooltip hidden={description === "_"}>
                            {description}
                        </Tooltip>
                    }
                >
                    {getIcon()}
                </OverlayTrigger>
            )}
        </>
    );
};

IconApp.propTypes = {
    iconClassName: PropTypes.string.isRequired,
    color: PropTypes.string,
    description: PropTypes.string,
    onClick: PropTypes.func,
};
